import { createSlice } from '@reduxjs/toolkit'
import { logger } from './Logger'

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    header: null,
    limits: {},
    reloadLimits: true,
    products: [],
    reloadProducts: true,    
    rxs: [],
    reloadRxs: true,
    vaccines: [],
    reloadVaccines: true,
    logo: null,
    signature: null
  },
  reducers: {
    setHeader: (state, headerData) => {
      logger.trace("store : setHeader", headerData)
      state.header = headerData
    },
    setLimits: (state, limitsData) => {
      logger.trace("store : setLimits", limitsData)
      state.limits = limitsData
      state.reloadLimits = false
    },
    askReloadLimits: (state) => {
      logger.trace("store : askReloadLimits")
      state.reloadLimits = true
    },
    setLogo: (state, logo) => {
      logger.trace("store : setLogo")
      state.logo = logo;
    },
    setSignature: (state, sign) => {
      logger.trace("store : setSignature")
      state.signature = sign;
    },
    setProducts: (state, data) => {
      logger.trace("store : setProducts", data)
      state.products = {...data,
        payload: data.payload && data.payload != "" ? data.payload : []
      }
      state.reloadProducts = false
    },
    askReloadProducts: (state) => {
      logger.trace("store : askReloadProducts")
      state.reloadProducts = true
    },

    setRxs: (state, data) => {
      logger.trace("store : setRxs", data)
      state.rxs = {...data,
        payload: data.payload && data.payload != "" ? data.payload : []
      }
      state.reloadRxs = false
    },
    askReloadRxs: (state) => {
      logger.trace("store : askReloadRxs")
      state.reloadRxs = true
    },

    setVaccines: (state, data) => {
      logger.trace("store : setVaccines", data)
      state.vaccines = {...data,
        payload: data.payload && data.payload != "" ? data.payload : []
      }
      state.reloadVaccines = false
    },
    askReloadVaccines: (state) => {
      logger.trace("store : askReloadVaccines")
      state.reloadVaccines = true
    },
  }
})

export const GetWeightConf = (conf) => {
  return conf?.weight
}
export const GetWeightType = (conf, type) => {
  return GetWeightConf(conf) ? GetWeightConf(conf)[type] : null
}
export const GetPaiementEmail = (conf) => {
  return conf?.paiement_email
}
export const GetLicense = (conf) => {
  return conf?.license
}
// Action creators are generated for each case reducer function
export const { setHeader, setLogo, setSignature, setLimits, askReloadLimits, setProducts, askReloadProducts, setRxs, setVaccines } = commonSlice.actions

export default commonSlice.reducer