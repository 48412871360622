
import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FlatHeaderRenderer from '../../header/FlatHeaderRenderer';
import { useDispatch, useSelector } from 'react-redux';
import {CreateButtonSection} from '../../view/CreateButtonSection';
import EmptyView from '../../view/EmptyView';
import { addRow, deleteRow, handleInputChange } from '../../view/TableEditionHandler';
import { convertToSystemDateFormat } from '../../date/DateUtils';
import VaccinTable from './VaccinTable';
import { GetEmptyItem, emptyVaccin } from './BaseVaccin';
import DayPicker from '../../date/DatePicker';
import VaccineSelector from './VaccineSelector';
import { useTranslation } from 'react-i18next';
import { addItemAnimal, updateItemAnimal } from '../common/ItemUtils';
import { LoadHeader } from '../../headers/HeaderLoader';
import { LoadVaccines } from '../../headers/VaccinesLoader';
import { canCreateVaccine, handleAction } from '../../../services/AccessHub';
import NoMoreActionView from '../../view/NoMoreActionView';
import { logger } from '../../common/Logger';
import { v4 as uuid } from 'uuid';


const calculateEffectiveVaccines = (vaccines, curDate, defaultItem) => { 
  if (!vaccines) {
    return [defaultItem]
  }

  logger.debug("calculateEffectiveVaccines : ", vaccines)
  //First, add default vaccine
  let effectiveVaccines = [defaultItem]
  //Construct a list of vaccine names to prevent duppliacte
  let vaccsName = [defaultItem.name]

  //Parse all vaccines consults
  for (const vaccineConsult of vaccines) {
    //Then, parse all vaccines in this consults
    for (const item of vaccineConsult.items) {
      ///TODO won't work in safari : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse
      const effect = Date.parse(item.effective)
      const rappel = Date.parse(item.rappel)
      //If the current date is between the effective date and the rappel date and not already part of the list, add the vaccine to the list
      if (curDate.getTime() > effect && curDate.getTime() < rappel && !vaccsName.includes(item.name)) {
        logger.debug("Current Item effective : ", item)
        effectiveVaccines.push(item)
        vaccsName.push(item.name)
      }
    }
  }
  return effectiveVaccines
}


export function CreateVaccine({itemToEdit, saveCallback}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isUpdate = (itemToEdit)
  const header = useSelector(state => state.common.header)
  const curClient = useSelector(state => state.client.client)
  const curAnimal = useSelector(state => state.client.animal)   


  const defaultItem = GetEmptyItem(header?.payload?.layout)
  const [vaccine, setVaccine] = useState(isUpdate ? itemToEdit : {...emptyVaccin, items: calculateEffectiveVaccines(curAnimal?.payload?.vaccines, new Date(), defaultItem)});
  const limits = useSelector(state => state.common.limits)?.payload

  React.useEffect(() => {
    handleSetNo();
  }, [vaccine, isUpdate]);


  const handleSetNo = () => {
    if (!isUpdate && vaccine.no === "") {
      logger.debug("Need new no")
      handleInputChange(vaccine, setVaccine, null, "no", uuid())
    }
  }

  logger.trace("curVaccine : ", vaccine)
  const internalHandleSave = async (event) => {
      event.preventDefault();

      if (isUpdate) {
        return updateItemAnimal(curAnimal.payload.key, "vaccines", vaccine, saveCallback, dispatch, t, "vaccine")
      } else {
        handleAction("vaccine", dispatch)
        return addItemAnimal(curAnimal.payload.key, "vaccines", vaccine, saveCallback, dispatch, t, "vaccine")
      }
  }


  const getView =(header, curClient, curAnimal, vaccine) => {
    return (<Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      <LoadHeader />
      <LoadVaccines />
      { isUpdate &&
        <VaccineSelector animal={curAnimal} vaccine={vaccine} disable={true}/>
      }
              
      <form id="vaccineForm"  onSubmit={internalHandleSave}>
        <FlatHeaderRenderer 
            headerData={header} 
            clientData={curClient} 
            animalData={curAnimal}
            extraData={{
              "date": <DayPicker 
                value={ vaccine.date}
                inputChange={ (e) => headerDateInputChange(vaccine, setVaccine, e)}
                /> }}/>
  
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align='center' variant="h5">
            Certificat de vaccination
          </Typography>  
          <VaccinTable 
            items={vaccine.items} 
            handleInputChange={(index, fieldName, value) => handleInputChange(vaccine, setVaccine, index, fieldName, value)} 
            deleteItem={(index) => deleteRow(vaccine, setVaccine, index)}
            isEditMode={true}
            isPrintMode={false}/>  
      <br/>
      <CreateButtonSection addRowHandler={() => addRow(vaccine, setVaccine, GetEmptyItem())} />
  </form>
  </Paper>)
  }

  if (!canCreateVaccine(limits)) {
    return (<NoMoreActionView/>)
  }

  if ((vaccine === undefined || vaccine === null) || (curAnimal === undefined || curAnimal === null)) {
    return (<EmptyView title={t('animal.noSelection')} />)
  }

  return getView(header?.payload, curClient?.payload, curAnimal?.payload, vaccine)
  
}

const headerDateInputChange = (originalObject, setMethod, value) => {
  logger.debug("headerDateInputChange : ", value)
  const newSysDate = convertToSystemDateFormat(value)

  //const d1 = new Date(newSysDate)
  //const d2 = new Date(originalObject.date)

  //const diffTime = new Date(newSysDate).getDate() - new Date(originalObject.date).getDate()
  //const diffTime = Math.abs(Date(newSysDate) - new Date(originalObject.date));
  //const diffTime = Math.abs(d2 - d1);
  //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


  var obj = handleInputChange(originalObject, setMethod, null, "date", newSysDate)
  for (let i = 0; i < originalObject.items.length; i++) {
    obj = handleInputChange(obj, setMethod, i, "effective", newSysDate)
    //const curRappel = obj.items[i].rappel
    //logger.trace("Old rappel : ", curRappel)
    

    //const newRappel = formatToSystemDate(new Date(curRappel).setDate(new Date(curRappel).getDate() + diffDays));

    //const newRappel = formatToSystemDate(new Date(new Date(curRappel).setTime(new Date(curRappel).getTime() + diff)))
    //logger.debug("new rappel : ", newRappel)

    //logger.debug("test",  formatToSystemDate(new Date(newRappel + "GMT-0500")))
    //obj = handleInputChange(obj, setMethod, i, "rappel", newRappel)
  }
};

