import { React} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addLoadingCount, subLoadingCount } from "../common/LoadingSlice";
import { useEffect } from "react";
import { setRxs } from "../common/CommonSlice";
import { messageStore } from '../common/InfoMessage';
import Headers from '../../api/Headers';

const FILE_NAME = "rxs.json"
export const LoadRxs = () => {
    const dispatch = useDispatch()
    const mustReload =  useSelector(state => state.common.reloadRxs)
    const curRxs = useSelector(state => state.common.rxs)
    useEffect(()=>{
       if (mustReload) {
            dispatch(addLoadingCount())
            new Headers().Get(FILE_NAME)
              .then(setRxs)
              .then(dispatch)
              .catch((error) => {
                  console.error("Error loading rxs : ", error)
                  messageStore.sendMessage("Error loading rxs", "error")
                  dispatch(setRxs([]))
              })
              .then(subLoadingCount)
              .then(dispatch)              
          }
      },[curRxs, mustReload]);
    return (<></>)
}
