import { React} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addLoadingCount, subLoadingCount } from "../common/LoadingSlice";
import { useEffect } from "react";
import { setVaccines } from "../common/CommonSlice";
import { messageStore } from '../common/InfoMessage';
import Headers from '../../api/Headers';

const FILE_NAME = "vaccines.json"
export const LoadVaccines = () => {
    const dispatch = useDispatch()
    const mustReload =  useSelector(state => state.common.reloadVaccines)
    const curVaccines = useSelector(state => state.common.vaccines)
    useEffect(()=>{
       if (mustReload) {
            dispatch(addLoadingCount())
            new Headers().Get(FILE_NAME)
              .then(setVaccines)
              .then(dispatch)
              .catch((error) => {
                  console.error("Error loading vaccines : ", error)
                  messageStore.sendMessage("Error loading vaccines", "error")
                  dispatch(setVaccines([]))
              })
              .then(subLoadingCount)
              .then(dispatch)              
          }
      },[curVaccines, mustReload]);
    return (<></>)
}
