import React  from 'react';
import { Autocomplete, Paper, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DayPicker from '../../date/DatePicker';
import DayHelpPicker from '../../date/DayHelpPicker';
import dayjs from 'dayjs';
import { convertToSystemDateFormat } from '../../date/DateUtils';
import { DeleteIcon } from '../../common/IconFactory';
import { useTranslation } from 'react-i18next';
import TextFieldStyle from '../../input/TextFieldStyle';
import { useSelector } from 'react-redux';


function VaccinTable({items, handleInputChange, deleteItem, isEditMode}) {
  const { t } = useTranslation();
  const vaccinesList = useSelector(state => state.common.vaccines)?.payload
  const headers = isEditMode ? GetEditHeader(t) : GetViewHeader(t)
  const rows = isEditMode ? GetEditRow(items, handleInputChange, deleteItem, vaccinesList) : getViewRow(items)

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        {headers}
        <TableBody>
          {rows}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function GetEditHeader(t) {
  return (
  <TableHead>
    <TableRow>
      <TableCell width={"30%"}><b>{t('vaccine.item.vaccine')}</b></TableCell>
      <TableCell width={"50px"} align="center"><b>{t('vaccine.item.effectif')}</b></TableCell>
      <TableCell width={"38%"} align="center"><b>{t('vaccine.item.nextDate')}</b></TableCell>
      <TableCell width="44px" align="right"></TableCell>
    </TableRow>
  </TableHead>)
}

function GetViewHeader(t) {
  return (
    <TableHead>
      <TableRow>
        <TableCell width={"50%"}><b>{t('vaccine.item.vaccine')}</b></TableCell>
        <TableCell align="center"><b>{t('vaccine.item.effectif')}</b></TableCell>
        <TableCell align="center"><b>{t('vaccine.item.nextDate')}</b></TableCell>
      </TableRow>
    </TableHead>
  )
}
  

function GetEditRow(items, handleInputChange, deleteItem, vaccinesList) {
  const { t } = useTranslation();
    return (
      items.map((row, index) => (
        <TableRow 
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell align="left" component="th" scope="row" padding='checkbox'>
            <Autocomplete
              id="txtName"
              freeSolo
              required
              disableClearable
              value={row.name}
              options={vaccinesList?.map((option) => option.nm)}
              onBlur={(event) => handleInputChange(index, 'name', event.target.value)}
              onChange={(event, newValue) => handleInputChange(index, 'name', newValue)}
              renderInput={(params) => 
                <TextField label={t('vaccine.item.vaccineLong')} {...params} 
                  variant="standard"
                  />}
              />

          </TableCell>
          <TableCell align="center" padding='none'>
            <DayPicker
              inputProps={{'aria-label': "txtEffective"}}
              label={t('dateSelectionLong')}
              value={dayjs(row.effective)}
              maxDate={dayjs(row.rappel)}
              inputChange={(e) => handleInputChange(index, 'effective', convertToSystemDateFormat(e))}
              />
          </TableCell>
          <TableCell align="center" padding='none'>
            <DayHelpPicker 
              label={t('dateSelectionLong')}
              referenceValue={row.effective}
              value={dayjs(row.rappel)}
              minDate={dayjs(row.effective)}
              inputChange={(e) => handleInputChange(index, 'rappel', convertToSystemDateFormat(e))}
              />
          </TableCell>
          <TableCell align="center" padding='none'><DeleteIcon  key={'btnDelete'+index} onClick={() => deleteItem(index)}/></TableCell>

        </TableRow>
      ))
    )
}

function getViewRow(items) {
    return (
        items.map((row, index) => (
        <TableRow 
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row" size='small'>
                {row.name}
            </TableCell>
            <TableCell align="center" size='small'>
                {row.effective}
            </TableCell>
            <TableCell align="center" size='small'>{row.rappel}</TableCell>
        </TableRow>)
        ))
}

export default VaccinTable